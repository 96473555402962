.nft-card {
  border: 0;
  box-sizing: border-box;
  border-radius: 20px;
  background: linear-gradient(
      42.11deg,
      #231f45 2.68%,
      rgba(22, 16, 63, 0) 26.86%
    ),
    linear-gradient(225.49deg, #231f45 19.84%, rgba(22, 16, 63, 0) 47.64%);
  background-repeat: no-repeat;
  margin: 10px;
  text-decoration: none;
}

.nft-card:hover {
  color: white;
}

.nft-card-title {
  text-transform: uppercase;
  font-weight: bold;
  font-family: druk;
  font-size: 10.5px;
  line-height: 130%;
  margin-top: 40px;
  padding-right: 25px;
}

.nft-card-title a {
  text-decoration: none;
}

.nft-card-subtitle {
  font-size: 11.5px;
}

.nft-card-text {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 11.6466px;
  line-height: 110%;
}

.nft-card-price-label {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #999FC9;
  margin-bottom: 5px;
}

.nft-card-price-value {
  font-family: Avenir;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  color: #FFFFFF;
  margin-bottom: 5px;
}

.nft-card-price-average {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #999FC9;
  margin-bottom: 1rem;
}

.nft-card-footer {
  position: absolute;
  bottom: 0;
  font-size: 9.87px;
  font-weight: 600;
}

.nft-card-preview {
  margin: 10px;
  height: 180px;
}

.nft-card-lg {
  width: 100%;
}

.nft-card-md {
  width: 36rem;
}

.nft-card-sm {
  width: 19rem;
  margin: 6px;
  border: 4px solid transparent;
}

a.nft-card-sm.common:hover {
  border: 4px solid #507FFF;
  .nft-card-title {
    color: #507FFF;
  }
}

.nft-card-sm.rare:hover {
  border: 4px solid #51F1FF;
  .nft-card-title {
    color: #51F1FF;
  }
}

.nft-card-sm.epic:hover {
  border: 4px solid #FF61EF;
  .nft-card-title {
    color: #FF61EF;
  }
}
