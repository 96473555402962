.moment-video {
  position: relative;
  width: 100%;
  height: 100%;

  .vid-gen-con {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .vid-gen-item {
      width: 90%;
      height: 90%;
      background-color: transparent;
      visibility: hidden;
    }
    .vid-gen-item.show {
      visibility: visible;
    }

    .vid {
      width: 80%;
      height: 80%;
    }
  }

  .vid-card-wrapper {
    position: relative;

    .vid-card {
      width: 100%;
    }
  }
}
