
.single-moment.card {
  background: linear-gradient(42.11deg, #231F45 2.68%, rgba(22, 16, 63, 0) 26.86%),
              linear-gradient(225.49deg, #231F45 19.84%, rgba(22, 16, 63, 0) 47.64%);
  border-radius: 12px;
  border-width: 0;

  margin: 25px;
  margin-top: 0;
}

.single-moment-more {
  margin-right: 15px;;
  margin-top: 15px;;
}

a.single-moment-back,
a.single-moment-back:hover,
a.single-moment-back:visited {
  text-decoration: none;
}

.single-moment-back-text {
  margin-left: 5px;

  font-family: Avenir;
  font-weight: 600;
  font-size: 18px;
  color: #6F77B7;
}

.single-moment-card-main-row {
  padding: 50px;
}

.single-moment .moment-visuals {
  width: 385px;
  height: 690px;
  background: url(../../assets/moment-video-background.png);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.single-moment-expand {
  margin-bottom: 19px;
  cursor: pointer;
}

.single-moment-text-available {
  font-family: Avenir;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}

.single-moment .moment-text h1 {
  font-family: Druk;
  font-style: normal;
  font-weight: bold;
  font-size: 34.03px;
  line-height: 130%;

  text-transform: uppercase;

  margin-bottom: 40px;
}

.single-moment .moment-text h2 {
  font-family: Druk;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;

  text-transform: uppercase;
  color: #6028ff;

  margin-bottom: 24px;
}

.single-moment .moment-text h3 {
  font-family: Druk;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 110%;

  text-transform: uppercase;

  margin-bottom: 64px;
}

.single-moment .moment-text h4 {
  font-family: Druk;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;

  text-transform: uppercase;
  color: #6028ff;
  opacity: 0.8;

  margin-bottom: 20px;
}

.single-moment .moment-text h5 {
  font-family: Druk;
  font-style: normal;
  font-weight: bold;
  font-size: 12.4432px;
  line-height: 130%;

  text-transform: uppercase;
  color: #6028ff;
  opacity: 0.8;

  margin-bottom: 5px;
}

.single-moment .moment-text p {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16.2091px;
  line-height: 130%;

  color: #e6ddff;

  margin-bottom: 47px;
}

.single-moment .btn-primary {
  width: 100%;
  height: 64px;

  background: #6028ff;
  border-radius: 8.73945px;
  border-width: 0;

  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 20.1007px;
  line-height: 150%;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 10px;
  }
}

.moment-tags {
  margin-top: 55px;
}

.moment-tag {
  min-width: 25.01%; // Don't fit 4 on the same line.
}

.single-moment .btn-secondary {
  margin-right: 12px;

  padding: 15px 20px 15px 20px;

  background: linear-gradient(0deg, #3d386f, #3d386f);
  border-radius: 6.43176px;
  border-width: 0;

  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16.4291px;
  line-height: 130%;

  color: #e4dbff;
}

.single-moment-expanded {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(7,3,41,0.9);

  video {
    width: 90%;
    height: 90%;
  }

  .vid-wrapper {
    position: relative;
    // 9:16 aspect ratio
    width: 56.25vh;
    height: 100vh;
  }
}
