
.sell-dialog-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(7,3,41,0.9)
}

.sell-dialog-close-button {
  position: absolute;
  color: white;
  background: url(../../assets/svg/close.svg);
  right: 10px;
  top: 10px;
  width: 34px;
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
}

.sell-dialog {
  position: relative;
  width: 1015px;
  height: 90%;
  border: 10px solid #24128F;
  background-color: #141130;
  border-radius: 20px;
  opacity: 1;
}

.sell-dialog-top-bg {
  position: absolute;
  width: 48px;
  height: 196px;
  right: 20%;
  top: 0;
  background: url(../../assets/svg/sell-dialog-top-bg.svg);
  background-repeat: no-repeat;
}

.sell-dialog-bottom-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 462px;
  height: 458px;
  background: url(../../assets/svg/sell-dialog-bottom-bg.svg);
  background-repeat: no-repeat;
}

.sell-dialog-content {
  position: relative;
  width: 60%;
  height: 100%;
  left: 20%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;

}

.sell-dialog-title-1 {
  width: 100%;
  font-family: Druk;
  font-weight: 700;
  font-style: normal;
  font-size: 35px;
}

.sell-dialog-title-2 {
  font-family: Druk;
  font-weight: 700;
  font-style: normal;
  font-size: 35px;
  color: #6028FF;
}

.sell-dialog-nft-card {
  margin-top: 50px;
  height: 250px;
  background-color: rgba(82, 35, 218, 0.7);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  .nft-card {
    background: none;
    height: 200px;
  }
}

.sell-dialog-input-container {
  margin-top: 50px;
  position: relative;
}

.sell-dialog-input-error {
  position: absolute;
  margin-left: 10px;
  color: red;
  top: 50%;
  transform: translateY(-50%);
}

.sell-dialog-input {
  width: 100%;
  border: 1px solid #6552D6;
  border-radius: 5px;
  height: 54px;
  background: none;
  color: #DEDEDE;
  text-align: center;
  font-family: Avenir;
  font-weight: 500;
  font-size: 25px;
}

.sell-dialog-price-info {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sell-dialog-price-helper {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  img {
    margin-right: 10px;
  }
}

.sell-dialog-buttons {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.sell-dialog-button-cancel {
  width: 48%;
  height: 54px;
  border: 1px solid #FFAAAA;
  color: #FFAAAA;
  border-radius: 5px;
  background: none;

  font-family: Avenir;
  font-weight: 500;
  font-size: 15px;
}

.sell-dialog-button-sell {
  width: 48%;
  height: 54px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Avenir;
  font-weight: 800;
  font-size: 15px;
  color: white;
  background: #6028FF;
  border: none;

  img {
    margin-right: 10px;
  }
}

.sell-dialog-button-sell.disabled {
  opacity: 0.65;
  cursor: default;
}

.sell-dialog-hr {
  background: url(../../assets/svg/sell-dialog-hr.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
}

.sell-dialog-marketplace-fee {
  font-family: Avenir;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #3D25CC;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sell-dialog-total-payout {
  font-family: Avenir;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  color: #FFFFFF;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sell-dialog-bottom-hr {
  margin-top: 16px;
  height: 9px;
  background: #2810BC;
}

.sell-dialog-footer {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Avenir;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  color: #3E28C5;
}
