.single-pack {
  width: 390px;

  background: linear-gradient(
      42.11deg,
      #231f45 2.68%,
      rgba(22, 16, 63, 0) 26.86%
    ),
    linear-gradient(225.49deg, #231f45 19.84%, rgba(22, 16, 63, 0) 47.64%),
    #070329;
  border-radius: 13.978px;
  border-width: 0;

  margin-left: 32px;
  margin-right: 10px;
  margin-bottom: 40px;
  padding: 25px;
  padding-bottom: 5px;
}

.single-pack .card-title {
  font-family: Druk;
  font-style: normal;
  font-weight: bold;
  font-size: 24.1779px;
  line-height: 27px;

  text-transform: uppercase;

  max-height: 54px;
  overflow-y: hidden;

  margin-bottom: 20px;
}

.single-pack.common .card-title {
  color: #5a7fff;
}

.single-pack.rare .card-title {
  color: #51f1ff;
}

.single-pack.epic .card-title {
  color: #ff61ef;
}

.single-pack .card-img-background {
  position: relative;
  width: 309px;
  height: 305px;

  border-radius: 14px;
  margin-bottom: 25px;
}

.single-pack.common .card-img-background {
  background: url(../../assets/inventory-pack-background-common.png);
}

.single-pack.rare .card-img-background {
  background: url(../../assets/inventory-pack-background-rare.png);
}

.single-pack.epic .card-img-background {
  background: url(../../assets/inventory-pack-background-epic.png);
}

.single-pack .card-img-top {
  position: absolute;
  top: -30px;
  left: 20px;
  transform: rotate(-7deg) scale(0.95);
}

.single-pack p {
  font-family: Avenir;
  font-style: normal;
  font-weight: bold;
  font-size: 18.9658px;
  line-height: 26px;
  text-transform: uppercase;
}

.single-pack small {
  font-family: Avenir;
  font-style: normal;
  font-weight: bold;
  font-size: 12.5802px;
  line-height: 17px;
  text-transform: uppercase;

  color: #606692;
}

.single-pack button {
  font-family: Avenir;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  background: #6028ff;
  text-transform: uppercase;

  border: 0px;

  padding: 8px 40px;
}
