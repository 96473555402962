
.modal-portal-close-button {
  position: absolute;
  color: white;
  background: url(../../assets/svg/close.svg);
  right: 10px;
  top: 10px;
  width: 34px;
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
