$card-con-width: 385px;
$card-con-height: 690px;

.reveal-con {
  position: relative;
  width: 100vw;
  height: 100vh;

  background-color: #240b8a00;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 500ms linear;
}

.reveal-con.fade-show {
  background-color: #240b8ada;
}

.reveal-wrapper {
  position: relative;
  width: 100%;
  height: $card-con-height;
}

.reveal-child {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.open-pack-prompt {
  transform: translate(0%, -100%);
  transition: transform 1s ease-out, opacity 1s linear;
  opacity: 0.0;
  z-index: 2;
}

.open-pack-prompt.show {
  transform: translate(0%, 0%);
  opacity: 1.0;
}

.open-pack-vid {
  transform: rotate(10deg);
}

.open-pack-vid.opening { 
  animation: open-pack-anim 1.5s ease-in-out;
  animation-fill-mode: forwards;
}

.open-pack-btn-con {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.open-pack-btn-con .btn, .reveal-finished-prompt .btn {
  font-family: Avenir;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;

  min-width: 135px;
  border-radius: 7px;
  padding: 10px 20px;
  margin-right: 10px;

  background-color: #6028ff;
  border-color: #6028ff;
  color: #ffffff;
}

.reveal-section {
  z-index: 1;
}

.reveal-moment-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(7,3,41,0.0);
  z-index: 100;
  display: none;
  transition: background-color 500ms linear;
}

.reveal-moment-backdrop.show {
  display: block;
}

.reveal-moment-backdrop.fade-show {
  background-color: rgba(7,3,41,0.9);
}

.reveal-moment {
  perspective: 1000px;
  transition: transform 500ms ease;
  transform-origin: 50% 50%;
  width: $card-con-width;
  height: $card-con-height;
  z-index: 0;
}

.reveal-moment.clickable {
  cursor: pointer;
}

.reveal-moment.show-front {
  // transform: rotateY(180deg);
}
.reveal-moment.move-to-front {
  z-index: 120;
}

.reveal-moment-flipper {
  backface-visibility: visible;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  transform: rotateY(0deg);
  width: 100%;
  height: 100%;
}

.reveal-moment.show-front .reveal-moment-flipper {
  animation: slow-spin-reveal 4s;
  animation-fill-mode: forwards;
}

.reveal-moment-front, .reveal-moment-back {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  backface-visibility: hidden;
}

.reveal-moment-back {
  background-image: url(../../../assets/click-to-reveal-300.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  transform: rotateY(0deg);
}

.reveal-moment-card {
  width: 100%;
}

.reveal-moment-front {
  transform: rotateY(180deg);
  background-image: url(../../../assets/moment-video-background.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0% 0%;
  transition: background-size 500ms ease-in-out;
}

.reveal-moment-front.show-video-bg {
  background-size: 100% 100%;
}

.reveal-finished-prompt {
  text-align: center;
  top: auto;
  bottom: 0;
  z-index: 200;
}

@keyframes slow-spin-reveal {
  0% {
    transform: rotateY(0deg);
    animation-timing-function: ease-in;
  }
  30% {
    transform: rotateY(-140deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: rotateY(-180deg);
  }
}

@keyframes open-pack-anim {
  0% {
    transform: rotate(10deg) scale(1.0);
    opacity: 1.0;
  }
  25% {
    transform: rotate(0deg) scale(1.1);
    opacity: 1.0;
  }
  50% {
    transform: rotate(0deg) scale(1.1);
    opacity: 1.0;
  }

  100% {
    transform: translate(0%, 100%) scale(1.1);
    opacity: 0.0;
  }
}
