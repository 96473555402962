$image-width: 852px;

.pack-sale-details {
  padding-top: 45px;
  padding-left: 40px;

  width: $image-width;

  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 29px;
}

.pack-sale-details > .header {
  font-family: Druk;
  font-style: normal;
  font-weight: bold;
  font-size: 33.53px;
  line-height: 44px;

  text-transform: uppercase;

  padding-top: 15px;
  padding-bottom: 20px;

  border: 0px;
  width: 100%;
  position: relative;
  display: block;
  text-align: left;
}

.pack-sale-details.common > .header {
  background: linear-gradient(
    233.86deg,
    #142d53 20.68%,
    rgba(12, 28, 54, 0) 55.41%
  );
  color: #5a88ff;
}

.pack-sale-details.rare > .header {
  background: linear-gradient(
    233.86deg,
    #1d1453 20.68%,
    rgba(22, 16, 63, 0) 55.41%
  );

  color: #51f1ff;
}

.pack-sale-details.epic > .header {
  background: linear-gradient(
    233.86deg,
    #331453 20.68%,
    rgba(22, 16, 63, 0) 55.41%
  );
  color: #ff5aee;
}

.pack-sale-details > .header:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100px;

  background-repeat: no-repeat;
  background-position: center;
  color: white;
  content: "";

  transition: transform 0.2s;
}

.pack-sale-details.common > .header:after {
  background-image: url(../../assets/svg/arrow-up-common.svg);
}

.pack-sale-details.rare > .header:after {
  background-image: url(../../assets/svg/arrow-up-rare.svg);
}

.pack-sale-details.epic > .header:after {
  background-image: url(../../assets/svg/arrow-up-epic.svg);
}

.pack-sale-details.collapsed > .header:after {
  transform: scaleY(-1);
}

.pack-sale-details h3 {
  font-family: Avenir;
  font-size: 23px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  text-align: left;

  margin-top: 40px;
}

.pack-sale-details > .content {
  margin-right: 85px;
  transition: opacity 0.2s;
}

.pack-sale-details.collapsed > .content {
  opacity: 0;
}

@keyframes hide-content {
  100% {
    opacity: 0;
    height: 0;
    display: none;
  }
  100% {
    opacity: 0;
    height: 0;
    display: none;
  }
}

.pack-sale-details p {
  margin-bottom: 40px;
}
