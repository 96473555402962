.login-status {
  display: inline-block;


  .logged-in {

  }

  .btn {
    font-family: Avenir;
    font-weight: bold;
    font-size: 17px;

    color: #15153F;

    height: 53px;
    border-radius: 237px;
    display: flex;
    align-items: center;
    margin-top: 43px;
    margin-left: 110px;
    padding: 0 25px;
  }

  .btn-logout {
    background: #F4606F;
  }
  .btn-logout:hover {
    text-decoration: underline;
  }

  .btn:hover {
    text-decoration: none;
  }

  .profile-pic {
    position: relative;
    top: 0px;

    display: inline-flex;
    vertical-align: baseline;

    font-family: Druk;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;

    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: white;
    text-decoration: none;
    color: #6028ff;
    align-items: center;
    justify-content: center;

    transition: top 100ms ease-in-out;
  }

  .profile-pic:hover {
    top: -2px;
  }

  .profile-pic-initial {
  }

  .logged-out {

  }

  .btn-login {
    color: #ffffff;
    font-weight: bold;
  }

  .btn-signup {
    color: #6028FF;
    background-color: #37FFFF;
  }
}
