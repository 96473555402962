
$image-width: 1300px;
$image-height: 426px;

$color-common: #57AFFF;
$color-rare: #51F1FF;
$color-epic: #FF61EF;

$bg-common: url(../../assets/packs-background-common.jpg);
$bg-rare: url(../../assets/packs-background-rare.jpg);
$bg-epic: url(../../assets/packs-background-epic.jpg);

.packs-sale-package {
  top: 45px;
  width: 300px;
  position: absolute;
}
.packs-sale-package.common {
  left: 950px;
}
.packs-sale-package.rare {
  left: 150px;
}
.packs-sale-package.epic {
  left: 950px;
}

.packs-sale-graphics {
  position: relative;
  width: 100%;
  height: $image-height;

  background-size: contain;
  border-radius: 10px;
  border-width: 10px;
  border-style: none;
  box-sizing: content-box;
  margin: 10px 0;
  overflow: hidden;
}

.packs-sale-graphics.highlighted {
  border-radius: 20px;
  border-style: solid;
  margin: 0;
}

.packs-sale-graphics.common {
  background: url(../../assets/packs-background-common.jpg);
  border-color: $color-common;
  color: $color-common;
  background-position: center;
}

.packs-sale-graphics.rare {
  background: url(../../assets/packs-background-rare.jpg);
  border-color: $color-rare;
  color: $color-rare;
  background-position: center;
}

.packs-sale-graphics.epic {
  background: url(../../assets/packs-background-epic.jpg);
  border-color: $color-epic;
  color: $color-epic;
  background-position: center;
}

.packs-sale-info {
  position: absolute;
  top: 0;
  width: 700px;
  height: $image-height;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.packs-sale-info.common {
  left: 120px;
}
.packs-sale-info.rare {
  left: 600px;
  width: 600px;
}
.packs-sale-info.epic {
  left: 200px;
}

.packs-sale-info-title {
    font-family: Druk;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 48px;
    /* or 96% */

    text-transform: uppercase;
    margin: 10px 0;
}

.packs-sale-info-rarity {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 30px;
  /* or 157% */
  color: #FFFFFF;

  text-transform: uppercase;
}

.packs-sale-info-details {
  font-family: Avenir;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 30px;
  /* or 157% */
  color: #FFFFFF;

  text-transform: uppercase;
}

.get-pack-button {
  margin: 15px 0;
  width: 359px;
  height: 72px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */
  color: #FFFFFF;
  border-radius: 10px;

  display: flex;
  align-items: center;
  text-align: center;

  background-color: #6028FF;
}

.get-pack-button.common.highlighted {
  background-color: $color-common;
}

.get-pack-button.rare.highlighted {
  background-color: $color-rare;
}

.get-pack-button.epic.highlighted {
  background-color: $color-epic;
}
