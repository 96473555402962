
$boxBorder : #5847BC 1px solid;

.moments-filters-container {
  border-top: $boxBorder;
  width: 254px;
}

.moments-filters-box {
  width: 254px;
  font-family: Avenir;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  border-bottom: $boxBorder;
}

.moments-filters-box.expanded {
  background: linear-gradient(0deg, #15153F, #15153F);
  height: 114px;
}

.moments-filter-header {
  height: 50px;
  padding-left: 15px;
  padding-right: 25px;
  cursor: pointer;
}

.moments-filter-label {
  color: #979BC6
}

.moments-filter-label.expanded {
  color: #FFFFFF
}

.moments-filter-arrow {
  width: 12px;
  height: 8px;
  background-position: center;
  background-repeat: no-repeat;
  padding-right: 7px;
}

.moments-filter-arrow-up {
  background-image: url(../../assets/svg/filter-arrow-up.svg);
}

.moments-filter-arrow-down {
  background-image: url(../../assets/svg/filter-arrow-down.svg);
}

.moments-filter-input {
  position: relative;
  padding-left: 15px;
}

.moments-filter-input-zoom {
  position: absolute;
  left: 27px;
}

.moments-filter-input input{
  background-color: #15153F;
  border: #FFFFFF 1px solid;
  color: #FFFFFF;
  width: 220px;
  height: 38px;
  padding-left: 40px;
}

.moments-filter-input input:focus {
  background-color: #2A3060;
  color: #FFFFFF;
}
