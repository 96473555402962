$image-width: 852px;
$image-height: 693px;

//

.pack-sale-graphics {
  position: relative;
  width: $image-width;
  height: $image-height;

  border-radius: 30px;
  background-size: contain;
}

.pack-sale-graphics.common {
  background: url(../../assets/pack-header-background-common.png);
}

.pack-sale-graphics.rare {
  background: url(../../assets/pack-header-background-rare.png);
}

.pack-sale-graphics.epic {
  background: url(../../assets/pack-header-background-epic.png);
}

//

.pack-rarity-text.common {
  background: url(../../assets/pack-rarity-text-common.png);
  background-repeat: no-repeat;
}

.pack-rarity-text.rare {
  background: url(../../assets/pack-rarity-text-rare.png);
  background-repeat: no-repeat;
}

.pack-rarity-text.epic {
  background: url(../../assets/pack-rarity-text-epic.png);
  background-repeat: no-repeat;
}

.pack-rarity-text {
  position: absolute;
  left: 40px;
  top: 180px;
  width: 300px;
  height: 300px;
}

//

.pack-sale-graphics > .pack-icon {
  position: absolute;
  top: 20px;
  right: 0px;
}

//

.pack-sale-graphics > .pack-name {
  position: absolute;
  left: 40px;
  width: 30%;
  height: 185px;
}

.pack-sale-graphics > .pack-name > p {
  font-family: Druk;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 33px;

  text-transform: uppercase;
}

.pack-sale-graphics.common > .pack-name {
  color: #5a88ff;
}

.pack-sale-graphics.rare > .pack-name {
  color: #51f1ff;
}

.pack-sale-graphics.epic > .pack-name {
  color: #ff5aee;
}

//

.pack-sale-cta {
  width: calc(100% - $image-width);
  padding-left: 45px;
}

.pack-sale-cta > p {
  font-family: Druk;
  font-style: normal;
  font-weight: bold;
  font-size: 33.53px;
  line-height: 44px;
  text-transform: uppercase;
}

.pack-sale-cta.common > p > span {
  color: #5a88ff;
}

.pack-sale-cta.rare > p > span {
  color: #51f1ff;
}

.pack-sale-cta.epic > p > span {
  color: #ff5aee;
}

.pack-sale-cta > .btn {
  width: 335px;
  height: 48px;

  background: #6028ff;
  border-width: 0;

  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 15.005px;
  line-height: 150%;

  text-transform: uppercase;
}

