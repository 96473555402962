.page.profile {
  padding-left: 0;
  padding-right: 0;
}

.inventory-info {
  margin-bottom: 35px;

  padding: 25px 50px 15px 50px;

  background: linear-gradient(
    225.37deg,
    #331453 19.83%,
    rgba(22, 16, 63, 0) 72.59%
  );
  border-radius: 7px;
}

.inventory-info p {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;

  margin: 0;
  padding: 0;
}
