.filter-header {
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 45px;
}

.filter-header .btn {
  border-width: 0;
  border-radius: 7px;
  height: 50px;

  margin-right: 10px;

  background: #2a3060;
  white-space: nowrap;

  :hover {
    background: #2a3060;
  }
}

.filter-header .btn:last-of-type {
  margin-right: 0;
}

.btn-filter {
  padding: 14px 12px 7px 12px;
}

.btn-filter:before {
  display: inline-block;
  content: url(../../assets/svg/filter.svg);
}

.filter-header .btn-sort {
  padding: 16px 12px 6px 16px;
}

.filter-header .btn-sort:before {
  display: inline-block;
  content: url(../../assets/svg/sort-down.svg);
  margin-right: 23px;
}

.filter-header .btn-primary.active {
  background: #6028ff;
}

.filter-header .btn-sort span {
  display: inline-block;

  position: relative;
  top: -5px;

  font-family: Avenir;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;

  margin-right: 13px;
}

.filter-header p {
  font-family: Avenir;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;

  margin-bottom: 0;
}

.filter-header small {
  font-weight: normal;
}

.filter-header .input-filter {
  margin-left: 10px;
}

.filter-header .search-input-zoom {
  position: absolute;
  left: 11px;
  top: 50%;
  transform: translateY(-50%);
}

.filter-header input {
  border-radius: 7px;
  background-color: #2A3060;
  border: none;
  color: #FFFFFF;
  width: 742px;
  height: 50px;
  padding-left: 40px;
}

.filter-header input:focus {
  background-color: #2A3060;
  color: #FFFFFF;
}


