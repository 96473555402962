
.marketplace-single-moment-price .price-value {
  font-family: Avenir;
  font-size: 25px;
  font-weight: bold;
  line-height: 34px;
  margin-left: 10px;
}

.marketplace-single-moment-royalty {
  font-family: Avenir;
  font-style: normal;
  font-size: 15px;
  color: #6F77B7;
  text-decoration: underline;
}

.marketplace-single-moment-buy {
  margin-top: 20px;

  .disabled {
    opacity: 30%;
  }
}

.marketplace-single-moment-connect {
  margin-top: 15px;

  font-family: Avenir;
  font-weight: 500;

  img {
    margin-right: 5px;
  }

  a {
    text-decoration: none;
    font-weight: bold;
  }
}

.marketplace-single-moment-error {
  margin-top: 15px;

  font-family: Avenir;
  font-weight: 500;

  img {
    margin-right: 5px;
  }
}
