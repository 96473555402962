.mock-modal-backdrop {
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9001;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.mock-modal {
  width: 500px;

  .card-header {
    display: flex;
    color: black;
    justify-content: space-between;
  }
}
