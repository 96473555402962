.sway-logo {
  height: 165px;
  margin-top: 10px;
  margin-right: 30px;
}

.swl-nav-bar {
  width: 100%;
  min-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  height: 135px;
  border-bottom: #2810BC 3px solid;
  display: flex;
  justify-content: center;
  background: url(../../assets/header-background.png);
  background-position: right;
  background-repeat: no-repeat;
  background-color: #15153F;

  .nav-link {
    color: white;
    height: 43px;
    margin-top: 55px;
    margin-left: 40px;
    text-transform: uppercase;

    font-family: Avenir;
    font-weight: bold;
    font-size: 20px;

    padding: 0 0 10px 0;
    color: #C8B7FE;
    border-bottom: transparent 9px solid;
  }
  .nav-link:hover {
    color: #5163F9;
  }
  .nav-link.active {
    color: white;
    border-bottom: #2810BC 9px solid;
  }
}
