
.page.marketplace {
  padding-left: 0;
  padding-right: 0;
}
.marketplace-header {
  position: relative;
  height: 254px;
  border-radius: 28px;
  overflow: hidden;
  margin-bottom: 76px;
}

.marketplace-header-top {
  height: 127px;
  background: url(../../assets/marketplace-header-background.png);
  background-position-x: center;
  background-repeat: no-repeat;
}

.inventory-header-title,
.marketplace-header-title {
  position: absolute;

  padding-left: 60px;

  font-family: Druk;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;

  color: #FFFFFF;
}

.marketplace-header-bottom {
  padding-left: 40px;
  height: 127px;
  background: #131736;
}

.marketplace-header-bottom .btn-secondary.current {
  background-color: #6028ff;
  border-color: #6028ff;
  color: #ffffff;
}

.marketplace-items-container {
  margin-top: 49px;
}
